export const InventoryDataLong = [
  {
    id: 0,
    name: "Sauce Labs Bike Light",
    desc: `A red light isn't the desired state in testing but it sure helps when riding your bike at night. Water-resistant with 3 lighting modes, 1 AAA battery included.`,
    price: 9.99,
    image_url: "bike-light-1200x1500.jpg",
  },
  {
    id: 1,
    name: "Sauce Labs Bolt T-Shirt (XS)",
    desc: `Get your testing superhero on with the Sauce Labs bolt T-shirt. From American Apparel, 100% ringspun combed cotton, heather gray with red bolt.`,
    price: 15.99,
    image_url: "bolt-shirt-1200x1500.jpg",
  },
  {
    id: 2,
    name: "Sauce Labs Onesie",
    desc: `Rib snap infant onesie for the junior automation engineer in development. Reinforced 3-snap bottom closure, two-needle hemmed sleeved and bottom won't unravel.`,
    price: 7.99,
    image_url: "red-onesie-1200x1500.jpg",
  },
  {
    id: 3,
    name: "Test.allTheThings() T-Shirt (Red) (XS)",
    desc: `This classic Sauce Labs t-shirt is perfect to wear when cozying up to your keyboard to automate a few tests. Super-soft and comfy ringspun combed cotton.`,
    price: 15.99,
    image_url: "red-tatt-1200x1500.jpg",
  },
  {
    id: 4,
    name: "Sauce Labs Backpack",
    desc: `carry.allTheThings() with the sleek, streamlined Sly Pack that melds uncompromising style with unequaled laptop and tablet protection.`,
    price: 29.99,
    image_url: "sauce-backpack-1200x1500.jpg",
  },
  {
    id: 5,
    name: "Sauce Labs Fleece Jacket (XS)",
    desc: `It's not every day that you come across a midweight quarter-zip fleece jacket capable of handling everything from a relaxing day outdoors to a busy day at the office.`,
    price: 49.99,
    image_url: "sauce-pullover-1200x1500.jpg",
  },
  {
    id: 6,
    name: "Sauce Labs Fleece Jacket (S)",
    desc: `It's not every day that you come across a midweight quarter-zip fleece jacket capable of handling everything from a relaxing day outdoors to a busy day at the office.`,
    price: 49.99,
    image_url: "sauce-pullover-1200x1500.jpg",
  },
  {
    id: 7,
    name: "Sauce Labs Fleece Jacket (M)",
    desc: `It's not every day that you come across a midweight quarter-zip fleece jacket capable of handling everything from a relaxing day outdoors to a busy day at the office.`,
    price: 49.99,
    image_url: "sauce-pullover-1200x1500.jpg",
  },
  {
    id: 8,
    name: "Sauce Labs Fleece Jacket (L)",
    desc: `It's not every day that you come across a midweight quarter-zip fleece jacket capable of handling everything from a relaxing day outdoors to a busy day at the office.`,
    price: 49.99,
    image_url: "sauce-pullover-1200x1500.jpg",
  },
  {
    id: 9,
    name: "Sauce Labs Fleece Jacket (XL)",
    desc: `It's not every day that you come across a midweight quarter-zip fleece jacket capable of handling everything from a relaxing day outdoors to a busy day at the office.`,
    price: 49.99,
    image_url: "sauce-pullover-1200x1500.jpg",
  },
  {
    id: 10,
    name: "Sauce Labs Fleece Jacket (XXL)",
    desc: `It's not every day that you come across a midweight quarter-zip fleece jacket capable of handling everything from a relaxing day outdoors to a busy day at the office.`,
    price: 49.99,
    image_url: "sauce-pullover-1200x1500.jpg",
  },
  {
    id: 11,
    name: "Test.allTheThings() T-Shirt (Red) (S)",
    desc: `This classic Sauce Labs t-shirt is perfect to wear when cozying up to your keyboard to automate a few tests. Super-soft and comfy ringspun combed cotton.`,
    price: 15.99,
    image_url: "red-tatt-1200x1500.jpg",
  },

  {
    id: 12,
    name: "Test.allTheThings() T-Shirt (Red) (M)",
    desc: `This classic Sauce Labs t-shirt is perfect to wear when cozying up to your keyboard to automate a few tests. Super-soft and comfy ringspun combed cotton.`,
    price: 15.99,
    image_url: "red-tatt-1200x1500.jpg",
  },

  {
    id: 13,
    name: "Test.allTheThings() T-Shirt (Red) (L)",
    desc: `This classic Sauce Labs t-shirt is perfect to wear when cozying up to your keyboard to automate a few tests. Super-soft and comfy ringspun combed cotton.`,
    price: 15.99,
    image_url: "red-tatt-1200x1500.jpg",
  },

  {
    id: 14,
    name: "Test.allTheThings() T-Shirt (Red) (L)",
    desc: `This classic Sauce Labs t-shirt is perfect to wear when cozying up to your keyboard to automate a few tests. Super-soft and comfy ringspun combed cotton.`,
    price: 15.99,
    image_url: "red-tatt-1200x1500.jpg",
  },

  {
    id: 15,
    name: "Test.allTheThings() T-Shirt (Red) (XL)",
    desc: `This classic Sauce Labs t-shirt is perfect to wear when cozying up to your keyboard to automate a few tests. Super-soft and comfy ringspun combed cotton.`,
    price: 15.99,
    image_url: "red-tatt-1200x1500.jpg",
  },

  {
    id: 16,
    name: "Test.allTheThings() T-Shirt (Red) (XXL)",
    desc: `This classic Sauce Labs t-shirt is perfect to wear when cozying up to your keyboard to automate a few tests. Super-soft and comfy ringspun combed cotton.`,
    price: 15.99,
    image_url: "red-tatt-1200x1500.jpg",
  },
  {
    id: 17,
    name: "Sauce Labs Bolt T-Shirt (M)",
    desc: `Get your testing superhero on with the Sauce Labs bolt T-shirt. From American Apparel, 100% ringspun combed cotton, heather gray with red bolt.`,
    price: 15.99,
    image_url: "bolt-shirt-1200x1500.jpg",
  },
  {
    id: 18,
    name: "Sauce Labs Bolt T-Shirt (L)",
    desc: `Get your testing superhero on with the Sauce Labs bolt T-shirt. From American Apparel, 100% ringspun combed cotton, heather gray with red bolt.`,
    price: 15.99,
    image_url: "bolt-shirt-1200x1500.jpg",
  },
  {
    id: 19,
    name: "Sauce Labs Bolt T-Shirt (XL)",
    desc: `Get your testing superhero on with the Sauce Labs bolt T-shirt. From American Apparel, 100% ringspun combed cotton, heather gray with red bolt.`,
    price: 15.99,
    image_url: "bolt-shirt-1200x1500.jpg",
  },
  {
    id: 20,
    name: "Sauce Labs Bolt T-Shirt (XXL)",
    desc: `Get your testing superhero on with the Sauce Labs bolt T-shirt. From American Apparel, 100% ringspun combed cotton, heather gray with red bolt.`,
    price: 15.99,
    image_url: "bolt-shirt-1200x1500.jpg",
  },
  {
    id: 21,
    name: "Sauce Labs Bolt T-Shirt (S)",
    desc: `Get your testing superhero on with the Sauce Labs bolt T-shirt. From American Apparel, 100% ringspun combed cotton, heather gray with red bolt.`,
    price: 15.99,
    image_url: "bolt-shirt-1200x1500.jpg",
  },
];
